/* 
 * Product Impression Finished
 *
 * @listens BLOXSubscriptionProductListViewed
 * @event dataLayer#productImpressionFinished 
 */
window.addEventListener('BLOXSubscriptionProductListViewed',function(ev){
    if(window.dataLayer && ev.detail.impression.length){
        dataLayer.push({
            'ecommerce':{
                'impressions': ev.detail.impression
            },
            'event':'tncms.event.trigger',
            'tncms': {
                'event': {
                    'trigger': {
                        'action': 'productImpressionsFinished',
                        'category': 'subscription',
                        'label':'services displayed to user'
                    }
                }
            }
        });
    }
});

/* 
 * Product Click
 *
 * @listens BLOXSubscriptionProductListViewed
 * @fires dataLayer#productClick
 */
window.addEventListener('BLOXSubscriptionProductClicked', function(ev){
    if(window.dataLayer && ev.detail.product){
        dataLayer.push({
            'ecommerce':{
                'click':{
                    'actionField': {'list': ev.detail.product.legacy_context},
                    'products':[{
                        'name': ev.detail.product.name,
                        'id': ev.detail.product.product_id,
                        'price': ev.detail.product.price,
                        'brand': ev.detail.product.brand,
                        'category': "subscription",
                        'quantity':1,
                        'variant': ev.detail.product.variant
                    }]
                }
            },
            'event':'tncms.event.trigger',
            'tncms': {
                'event': {
                    'trigger': {
                        'action': 'productClick',
                        'category': 'subscription',
                        'label':'user clicked on a service'
                    }
                }
            }
        });
    }
});

/* 
 * Product Detail Viewed
 *
 * @listens BLOXSubscriptionProductListViewed
 * @fires dataLayer#productClick
 */
window.addEventListener('BLOXSubscriptionProductViewed', function(ev){
    if(window.dataLayer && ev.detail.product){
        dataLayer.push({
            'ecommerce':{
                'detail':{
                    'actionField': {'list': ev.detail.product.legacy_context},
                    'products':[{
                        'name': ev.detail.product.name,
                        'id': ev.detail.product.product_id,
                        'price': ev.detail.product.price,
                        'brand': ev.detail.product.brand,
                        'category': 'subscription',
                        'variant': ev.detail.product.variant
                    }]
                }
            },
            'event':'tncms.event.trigger',
            'tncms': {
               'event': {
                  'trigger': {
                     'action': 'productDetailViewed',
                     'category': 'subscription',
                     'label':'loaded service purchase page'
                  }
               }
            },
            'eventCallback':function(){ }
        });
    }
});

/*
 * Product Remove From Cart
 *
 * @listens BLOXSubscriptionProductRemoved
 * @fires dataLayer#removeFromCart
 */
window.addEventListener('BLOXSubscriptionProductRemoved', function(ev){
    if(window.dataLayer && ev.detail.product){
        dataLayer.push({
            'ecommerce':{
                'remove':{
                    'products': [{
                        'name': ev.detail.product.name,
                        'id': ev.detail.product.product_id,
                        'price': ev.detail.product.price,
                        'brand': ev.detail.product.brand,
                        'category': 'subscription',
                        'variant': ev.detail.product.variant
                    }]
                }
            },
            'event':'tncms.event.trigger',
            'tncms': {
                'event': {
                    'trigger': {
                        'action': 'removeFromCart',
                        'category': 'subscription',
                        'label':'user selected an alternate rate'
                    }
                }
            }
        });
    }
});

/*
 * Product Add to Cart
 *
 * @listens BLOXSubscriptionProductAdded
 * @fires dataLayer#addToCart
 */
window.addEventListener('BLOXSubscriptionProductAdded', function(ev){
    var products = [];

    if(ev.detail.product){
        products.push({
            'name': ev.detail.product.name,
            'id': ev.detail.product.product_id,
            'price': ev.detail.product.price,
            'brand': ev.detail.product.brand,
            'category': 'subscription',
            'variant': ev.detail.product.variant,
            'quantity': 1
        });
    }

    if(window.dataLayer && ev.detail.product){
        dataLayer.push({
            'ecommerce':{
                'currencyCode': ev.detail.product.currency,
                'add':{
                    'products': products
                }
            },
            'event':'tncms.event.trigger',
            'tncms': {
                'event': {
                    'trigger': {
                        'action': 'addToCart',
                        'category': 'subscription',
                        'label':'user added a rate'
                    }
                }
            }
        });
    }
});


/*
 * Checkout Started
 *
 * does not currently fire a dataLayer event
 */
window.addEventListener('BLOXSubscriptionCheckoutStarted', function(ev){
});

/*
 * Checkout Step Viewed
 *
 * @fires dataLayer#checkout
 */
window.addEventListener('BLOXSubscriptionCheckoutStepViewed', function(ev){
    var products = [];

    if(window.dataLayer && ev.detail.products && ev.detail.step == 1){
        ev.detail.products.forEach(function(product){
            products.push({
                'name': product.name,
                'id': product.product_id,
                'price': product.price,
                'brand': product.brand,
                'category': 'subscription',
                'variant': product.variant,
                'quantity': 1
            });
        });
    
        window.dataLayer.push({
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': ev.detail.step},
                    'products':products
                }
            },
            'event':'tncms.event.trigger',
            'tncms': {
                'event': {
                    'trigger': {
                        'action': 'checkout',
                        'category': 'subscription',
                        'label':'user began filling out payment information'
                    }
                }
            }
        });
    }
});



/*
 * Checkout Step Viewed
 *
 * does not currently fire a dataLayer event
 */
window.addEventListener('BLOXSubscriptionCheckoutStepCompleted', function(ev){
});

/*
 * Payment Info Entered
 *
 * does not currently fire a dataLayer event
 */
window.addEventListener('BLOXSubscriptionPaymentInfoEntered',function(ev){
});

/*
 * Order Completed
 *
 * @fires dataLayer#purchaseComplete
 */
window.addEventListener('BLOXSubscriptionOrderCompleted', function(ev){
    var products = [];

    if(window.dataLayer && ev.detail.products){
        ev.detail.products.forEach(function(product){
            products.push({
                'name': product.name,
                'id': product.product_id,
                'price': product.price,
                'brand': product.brand,
                'category': 'subscription',
                'variant': product.variant,
                'quantity': 1
            });
        });
    
        dataLayer.push({
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': ev.detail.order_id,
                        'affiliation': ev.detail.products[0].brand,
                        'revenue': ev.detail.products[0].price,
                        'tax': sessionStorage.getItem('__tnt.service.rate.tax'),
                        'shipping': '', // this will probably never apply to us
                        'coupon': ''
                    },
                    'products': products
                }
            },
            'event':'tncms.event.trigger',
            'tncms': {
               'event': {
                  'trigger': {
                     'action': 'purchaseComplete',
                     'category': 'subscription',
                     'label':'service purchase success page, purchase complete'
                  }
               }
            }
        }); // end of datalayer push
    }
});